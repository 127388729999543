* {
  font-family: 'Acme', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.cdnfonts.com/css/graffiti-font');
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

.numbers:focus {
  color: yellow;
  text-shadow: 0 0 10px #FFFFFF;
}

.numbersOn {
  color: yellow;
  text-shadow: 0 0 10px #FFFFFF;
}

.dynamiteDiv4 {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  margin-right: 1%;
}


@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.formMain2L {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.726);
}

.formMain-2L {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  background-color: rgba(0, 0, 0, 0);
  animation: popupAnimation 0.5s ease forwards;
  border-radius: 10px;
}

@keyframes borderFade3 {
  0% {
    border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
  }

  100% {
    border-image-source: linear-gradient(to right, #979797e5, #cfcfcfea);
  }
}

.introMint:active {
  box-shadow: -2px 2px rgb(201, 110, 0);
  transform: translateY(2px);
  background-color: rgb(216, 119, 0);
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 2000000000000;
  position: fixed;
}

@media screen and (max-width: 499px) {
  
   
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
     margin-left: 2.5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 50px;
    height: 50px;
  }
  
  .loader2 {
    height: 20px;
    width: 80px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/15px 15px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;
  }

  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 8px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 8px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 8px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 2.8em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 0.5px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 10px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .socialIcon img {
    width: 35px;
    height: 35px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 8px;
    font-size: 12px;
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 50px;
    margin-top: 30px;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#1897e6, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;
    text-align: center;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.76);
    margin-bottom: 30px;
  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: cover;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 90s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.1rem;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
    display: none;
  }

  .rmMob {
    width: 100%;
  }

  .logoPic {
    width: 80px;
    border-radius: 100%;
    border: 2px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 18px;
    width: 95%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 12px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: none;
  }

  .connect2Mob{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 7vh;
    background-color: #64646433;
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    border-radius: 50px;
    box-shadow: -8px 8px rgba(0, 0, 0, 0.329);
    border: 5px solid rgb(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .boxWrap2Footer {
    padding-top: 70px;
    padding-bottom: 50px;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 150px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.733);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 35%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

 
  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 54%;
  }

  .tier {
    cursor: pointer;
    color: rgb(0, 0, 0);
     transition: 0.3s ease-in-out;
    font-size: 15px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5); 
     width: 120px;
    text-align: center;
    padding: 3px;
  }

    .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 15px;
    width: 120px;
    text-align: center;
    background-color: #00000075;
    border-radius: 50px;
    padding: 3px;
  }



  .galleryPics {
    display: flex;
    justify-content: space-evenly;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 50px;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 25px;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.397);
    box-shadow: -5px 5px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 110px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 2px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 110px;
    /* Set the width */
    height: 110px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 30px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 15px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 110px;
    height: 110px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 20px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(201, 110, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgb(0, 0, 0);
    border: none;
  }

  .boomGif {
    width: 35%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -40%;
  }

  .rn {
    width: 40%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 60%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 25%;
    margin-left: 5%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 38%;
    margin-left: 60%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 35%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 50px;
    height: 50px;
  }
  
  .loader2 {
    height: 20px;
    width: 80px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/15px 15px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 3em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 0.5px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 10px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .socialIcon img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 50px;
    margin-top: 30px;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#1897e6, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;
    text-align: center;
    -webkit-text-stroke: 0.01px rgba(255, 255, 255, 0.76);
    margin-bottom: 30px;
  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: cover;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 90s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.1rem;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
    display: none;
  }

  .rmMob {
    width: 100%;
  }

  .logoPic {
    width: 90px;
    border-radius: 100%;
    border: 2px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 18px;
    width: 95%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 18px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    border-radius: 50px;
    box-shadow: -8px 8px rgba(0, 0, 0, 0.329);
    border: 5px solid rgb(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .boxWrap2Footer {
    padding-top: 70px;
    padding-bottom: 50px;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 50px;
    height: 50px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 150px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1px black;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 35%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 50%;
  }

  .tier {
    cursor: pointer;
    color: rgb(0, 0, 0);
     transition: 0.3s ease-in-out;
    font-size: 15px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5); 
     width: 100px;
    text-align: center;
 
  }

    .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 15px;
    width: 100px;
    text-align: center;
    background-color: #00000075;
    border-radius: 50px;
  }


  .galleryPics {
    display: flex;
    justify-content: space-evenly;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 50px;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 25px;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.397);
    box-shadow: -5px 5px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 110px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 2px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 110px;
    /* Set the width */
    height: 110px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 110px;
    height: 110px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 25px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(201, 110, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgb(0, 0, 0);
    border: none;
  }

  .boomGif {
    width: 35%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -40%;
  }

  .rn {
    width: 40%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 40%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 25%;
    margin-left: 5%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 35%;
    margin-left: 70%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 35%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
 
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 50px;
    height: 50px;
  }

  .loader2 {
    height: 20px;
    width: 80px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/15px 15px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 10px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 3em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 0.5px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 10px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .socialIcon img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 10px
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 40px;
    margin-top: 30px;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#1897e6, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;
    text-align: center;
    -webkit-text-stroke: 0.01px rgba(255, 255, 255, 0.76);
    margin-bottom: 30px;
  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 90s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
    display: none;
  }

  .rmMob {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    border-radius: 100%;
    border: 2px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 20px;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 25px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 30px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 50%;
    border-radius: 50px;
    box-shadow: -8px 8px rgba(0, 0, 0, 0.329);
    border: 5px solid rgb(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 180px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 23px;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 25%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 90%;
  }

  .tier {
    cursor: pointer;
    color: rgb(252, 252, 252);
     transition: 0.3s ease-in-out;
    font-size: 18px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5); 
    background-color: #1c331165;
    width: 100px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;
  }

    .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 18px;
    background-color: #1c331165;
    width: 100px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;

  }

  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.397);
    box-shadow: -5px 5px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 110px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 2px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 110px;
    /* Set the width */
    height: 110px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 110px;
    height: 110px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 18px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 30px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(201, 110, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 19px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgb(0, 0, 0);
    border: none;
  }

  .boomGif {
    width: 25%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -30%;
  }

  .rn {
    width: 25%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 25%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 15%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 25%;
    margin-left: 75%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 60px;
    height: 60px;
  }
  

 
  .loader2 {
    height: 20px;
    width: 80px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/15px 15px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rmMob {
    display: none;
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 4em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 1px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 10px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
  }

  .socialIcon img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 10px
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 40px;
    margin-top: 5%;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#5bbbf7, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;

  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 90s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    border-radius: 100%;
    border: 2px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 20px;
    text-align: left;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 25px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 30px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 350px;
    border-radius: 50px;
    box-shadow: -8px 8px rgba(0, 0, 0, 0.329);
    border: 5px solid rgb(0, 0, 0);
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 200px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 28px;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 20%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
    margin-top: -20%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 90%;
  }

  .tier {
    cursor: pointer;
    color: rgb(252, 252, 252);
     transition: 0.3s ease-in-out;
    font-size: 20px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5); 
    background-color: #1c331165;
    width: 150px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;
  }

    .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 20px;
    background-color: #1c331165;
    width: 150px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;

  }

  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.397);
    box-shadow: -5px 5px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 200px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 2px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 200px;
    /* Set the width */
    height: 200px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 200px;
    height: 200px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 35px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(201, 110, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 19px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgb(0, 0, 0);
    border: none;
  }

  .boomGif {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -20%;
  }

  .rn {
    width: 20%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 20%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 10%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 15%;
    margin-left: 85%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 15%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {  
    width: 65px;
    height: 65px;
  }  

  .loader2 {
    height: 20px;
    width: 80px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/15px 15px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  



  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 15px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rmMob {
    display: none;
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 4em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 1px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 10px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
  }

  .socialIcon img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 10px
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 40px;
    margin-top: 5%;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#5bbbf7, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;
  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 90s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
  }

  .logoPic {
    width: 100px;
    border-radius: 100%;
    border: 2px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 20px;
    text-align: left;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 25px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 30px;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 400px;
    border-radius: 50px;
    box-shadow: -8px 8px rgba(0, 0, 0, 0.329);
    border: 5px solid rgb(0, 0, 0);
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
  background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 200px;
    margin-bottom: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 28px;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 20%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
    margin-top: -20%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 60%;
  }

  .tier {
    cursor: pointer;
    color: rgb(252, 252, 252);
     transition: 0.3s ease-in-out;
    font-size: 25px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5); 
    background-color: #1c331165;
    width: 150px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;
  }

    .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    font-size: 25px;
    background-color: #1c331165;
    width: 150px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgb(112, 255, 68);
    padding: 5px;

  }

  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.397);
    box-shadow: -5px 5px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 200px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 2px black solid;
  }


  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 200px;
    /* Set the width */
    height: 200px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 200px;
    height: 200px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }


  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 200px;
    /* Set the width */
    height: 200px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 200px;
    height: 200px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  }

  .mintBtn {
    font-weight: bolder;
    font-size: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 5px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 35px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(201, 110, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 19px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.541), 1px 1px 1px rgb(0, 0, 0);
    border: none;
  }

  .boomGif {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -20%;
  }

  .rn {
    width: 20%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 20%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 10%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 15%;
    margin-left: 85%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 15%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 1920px) {
   
  
#sticky-button {
  position: sticky;
  visibility: hidden;
  position: sticky;
  bottom: 10%;
   margin-left: 1%;
  cursor: pointer;
  z-index: 1;
  z-index: 100000;
  transition: transform .4s;
}

.stickyBtn2 {

  width: 100px;
  height: 100px;
}


  .loader2 {
    height: 35px;
    width: 130px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/20px 20px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;

    background-color: rgba(172, 67, 67, 0);
    width: 150px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  
  .check {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 34px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 24px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 34px;
    color: #fc4741;
    text-align: center;
  }


  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 20px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 20px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 20px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }


  .rmMob {
    display: none;
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 6em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 1px white;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 8px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 20px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 35% 35%/6px 6px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
  }

  .socialIcon img {
    width: 70px;
    height: 70px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 13px;
    font-size: 24px;
    border: 4px black solid;
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 60px;
    margin-top: 5%;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#5bbbf7, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;

  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 80s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 32px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
  }

  .logoPic {
    width: 150px;
    border-radius: 100%;
    border: 4px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 28px;
    text-align: left;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 35px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 50px;
    border-radius: 80px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 600px;
    border-radius: 70px;
    box-shadow: -13px 13px rgba(0, 0, 0, 0.329);
    border: 8px solid rgb(0, 0, 0);
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 95px;
    height: 95px;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 280px;
    margin-bottom: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 45px;
    margin-top: 30px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 1.5px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 20%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
    margin-top: -20%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
     display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    width: 60%;
  }

  .tier {
    cursor: pointer;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    transition: 0.3s ease-in-out;
    font-size: 35px;
    background-color: #1c331165;
    width: 230px;
    text-align: center;
    border-radius: 25px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(112, 255, 68);
    padding: 7px;
  }

  .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    font-size: 35px;
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    background-color: #1c331165;
    width: 230px;
    text-align: center;
    border-radius: 25px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(112, 255, 68);
    padding: 7px;
  }

  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 28px;
    border-radius: 25px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid rgba(255, 255, 255, 0.397);
    box-shadow: -8px 8px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .images_show {
    width: 290px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 4px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 290px;
    /* Set the width */
    height: 290px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 290px;
    height: 290px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 4px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 28px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 8px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 28px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 8px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 55px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 15px;
    color: white;
    border-radius: 20px;
    box-shadow: -8px 8px rgb(201, 110, 0);
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 28px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -2px -2px 2px rgba(255, 255, 255, .1), 2px 2px 2px rgb(0, 0, 0);
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .boomGif {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -20%;
  }

  .rn {
    width: 20%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 20%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 10%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 15%;
    margin-left: 85%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 15%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
    
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 130px;
    height: 130px;
  }
  

  .loader2 {
    height: 45px;
    width: 170px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/30px 30px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }
  

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 60px;

    background-color: rgba(172, 67, 67, 0);
    width: 200px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 44px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 44px;
    color: #fc4741;
    text-align: center;
  }

  

  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 28px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 28px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 28px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rmMob {
    display: none;
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 8em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 1px white;
    text-align: center;
    margin-top: 50px;
    letter-spacing: 10px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 25px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 95%, #0000) 40% 40%/15px 15px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
  }

  .socialIcon img {
    width: 95px;
    height: 95px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 22px;
    font-size: 32px;
    border: 5px black solid;
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 80px;
    margin-top: 5%;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#5bbbf7, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;

  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 12rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 12rem;
    line-height: 12rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 75s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 45px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
  }

  .logoPic {
    width: 200px;
    border-radius: 100%;
    border: 5px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 42px;
    text-align: left;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 45px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 60px;
    border-radius: 90px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 10px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 800px;
    border-radius: 85px;
    box-shadow: -16px 16px rgba(0, 0, 0, 0.329);
    border: 10px solid rgb(0, 0, 0);
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 120px;
    height: 120px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 370px;
    margin-bottom: 45px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 50px;
    margin-top: 45px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 2px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 20%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
    margin-top: -20%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
    margin-right: 60px;
  }

  .tier {
    cursor: pointer;
    color: white;
    text-shadow: -2px -2px 2px rgba(255, 255, 255, .1), 2px 2px 2px rgba(0, 0, 0, .5);
    transition: 0.3s ease-in-out;
    background-color: #1c331165;
    width: 280px;
    text-align: center;
    border-radius: 40px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgb(112, 255, 68);
    padding: 15px;
    font-size: 41px;
  }

  .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    font-size: 32px;
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -2px -2px 2px rgba(255, 255, 255, .1), 2px 2px 2px rgba(0, 0, 0, .5);
    width: 280px;
    text-align: center;
    border-radius: 40px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgb(112, 255, 68);
    padding: 15px;
    font-size: 41px;
  }

  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 40px;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 6px solid rgba(255, 255, 255, 0.397);
    box-shadow: -10px 10px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 38px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .images_show {
    width: 400px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 6px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 400px;
    /* Set the width */
    height: 400px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 400px;
    height: 400px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 6px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }


  .mintBtn {
    font-weight: bolder;
    font-size: 38px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 8px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 38px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 8px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 69px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 22px;
    color: white;
    border-radius: 20px;
    box-shadow: -10px 10px rgb(201, 110, 0);
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 35px;
    text-align: center;
    margin-bottom: 70px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -3px -3px 3px rgba(255, 255, 255, .1), 3px 3px 3px rgb(0, 0, 0);
    border: none;
    margin-left: 20px;
    margin-right: 20px;
  }

  .boomGif {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -20%;
  }

  .rn {
    width: 20%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 20%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 10%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 15%;
    margin-left: 85%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 15%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 3840px) {

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 10%;
     margin-left: 1%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  
  
  .stickyBtn2 {
  
    width: 200px;
    height: 200px;
  }

  .loader2 {
    height: 85px;
    width: 300px;
    transform: rotate(-30deg);
    background: 
      radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/45px 45px no-repeat
      #ddd;
    animation:
      l17-0 2s infinite alternate linear,
      l17-1 2s infinite cubic-bezier(.75,0,1,.6);
  }
  @keyframes l17-0 {
      50% {transform:rotate(30deg)}
  }
  @keyframes l17-1 {
      50% {background-position: right bottom}
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 90px;

    background-color: rgba(172, 67, 67, 0);
    width: 300px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  
  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .success-message {
    color: #99ff9b;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 67px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 67px;
    color: #fc4741;
    text-align: center;
  }


  .galleryMainPlatinum {
    background: url('./assets/platinum.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 40px solid;
    border-image: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    border-image-slice: 1;
    animation: borderFade1 3s infinite alternate;
    /* Adjust the duration as needed */
    contain: content;
  }

  @keyframes borderFade1 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, #e5e4e2e3, #c0c0c0e3);
    }
  }

  .galleryMainGold {
    background: url('./assets/gold.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 40px solid;
    border-image: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    border-image-slice: 1;
    animation: borderFade2 3s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes borderFade2 {
    0% {
      border-image-source: linear-gradient(to right, #97979700, #cfcfcf00);
    }

    100% {
      border-image-source: linear-gradient(to right, rgba(255, 217, 0, 0.89), rgba(255, 187, 0, 0.911));
    }
  }

  .galleryMainSilver {
    background: url('./assets/silver.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    border: 40px solid;
    border-image: linear-gradient(to right, #979797e5, #cfcfcfea);
    /* Silver color gradient */
    border-image-slice: 1;
    animation: borderFade3 3s infinite alternate;
    /* Adjust the duration as needed */
  }


  .rmMob {
    display: none;
  }

  .loadTxt {
    text-align: center;
    font-family: 'Bangers', system-ui;
    font-size: 12em;
    font-weight: bold;
    color: #ff0000;
    /* Red color for text */
    -webkit-text-stroke: 1px white;
    text-align: center;
    margin-top: 70px;
    letter-spacing: 15px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  .loader {
    display: inline-flex;
    gap: 40px;
  }

  .loader:before,
  .loader:after {
    content: "";
    height: 70px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, #000 100%, #0000) 45% 45%/20px 20px no-repeat #fff;
    animation: l5 3s infinite;
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .iconsF {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .in2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .intro img {
    width: 90%;
  }

  .socialIcon img {
    width: 145px;
    height: 145px;
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    /* Smooth transition for zoom effect */
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    /* Zoom effect on hover */
    animation: shake2 0.5s ease-in-out;
    /* Shake animation on hover */
  }

  @keyframes shake2 {

    0%,
    100% {
      transform: translateX(0);
    }

    25%,
    75% {
      transform: translateX(-5px);
      /* Adjust the amount of shake as needed */
    }

    50% {
      transform: translateX(5px);
    }
  }

  .wallet2 {
    background-color: rgb(219, 219, 219);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for zoom effect */
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding: 28px;
    font-size: 48px;
    border: 8px black solid;
  }

  .wallet2:hover {
    transform: scale(1.2);
  }

  .wallet3 {
    font-size: 120px;
    margin-top: 5%;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(#5bbbf7, #f1e20c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    animation: shake 50s linear infinite;

  }

  @keyframes shake {

    0%,
    100% {
      transform: translateX(0);
    }

    2.5%,
    7.5%,
    12.5%,
    17.5%,
    22.5%,
    27.5%,
    32.5%,
    37.5%,
    42.5%,
    47.5%,
    52.5%,
    57.5%,
    62.5%,
    67.5%,
    72.5%,
    77.5%,
    82.5%,
    87.5%,
    92.5%,
    97.5% {
      transform: translateX(-10px);
    }

    5%,
    10%,
    15%,
    20%,
    25%,
    30%,
    35%,
    40%,
    45%,
    50%,
    55%,
    60%,
    65%,
    70%,
    75%,
    80%,
    85%,
    90%,
    95% {
      transform: translateX(10px);
    }
  }


  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 16rem;
    padding-left: 100%;
  }

  .ticker-wrap-1 {
    width: 100%;
    overflow: hidden;
    background-image: url('./assets/line.jpg');
    background-size: contain;

  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 16rem;
    line-height: 16rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 75s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 4rem;
    font-size: 68px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Graffiti font', sans-serif;
    font-weight: bolder;
  }

  .rm {
    width: 100%;
  }

  .logoPic {
    width: 290px;
    border-radius: 100%;
    border: 7px solid rgb(0, 0, 0);
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #ffffff;
    font-size: 60px;
    text-align: left;
    width: 90%;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 70px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .connect2Mob{
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-top: 7vh;
    background-color: #9b9a9a33;
    padding: 80px;
    border-radius: 130px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 12px solid rgba(71, 71, 71, 0.185);
  }

  .light {
    background: url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 1200px;
    border-radius: 140px;
    box-shadow: -24px 24px rgba(0, 0, 0, 0.329);
    border: 12px solid rgb(0, 0, 0);
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 5%;
    /*  background: rgb(55,108,6);
    background: linear-gradient(0deg, rgba(55,108,6,1) 0%, rgba(164,182,0,1) 50%, rgba(105,203,13,1) 100%);}*/
    background: url('./assets/grass.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .iconsF img {
    width: 185px;
    height: 185px;
    margin-left: 50px;
    margin-right: 50px;
    transition: transform .2s;
  }

  .iconsF img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 550px;
    margin-bottom: 55px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border: 6px solid black;
  }


  .imgH {
    text-align: center;
    color: #ffda73;
    text-transform: capitalize;
    font-size: 16px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 80px;
    margin-top: 55px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    -webkit-text-stroke: 3px black;
  }

  .hash {
    color: #ebff7d;
  }

  .bomb {
    width: 20%;
    bottom: 0;
    position: sticky;
    margin-left: 3%;
    margin-top: -20%;
  }

  .galleryMain {
    background: url('./assets/bg2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .tierMain {
    font-weight: 500;
    margin-right: 80px;
  }

  .tier {
    cursor: pointer;
    color: white;
    text-shadow: -3px -3px 3px rgba(255, 255, 255, .1), 3px 3px 3px rgba(0, 0, 0, .5);
    transition: 0.3s ease-in-out;
    width: 400px;
    text-align: center;
    border-radius: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 6px solid rgb(112, 255, 68);
    padding: 20px;
    font-size: 65px;
  }

  .tier:hover {
    color: #ffec3e;
  }

  .tierOn {
    font-size: 45px;
    cursor: pointer;
    color: #ffec3e;
    text-shadow: -3px -3px 3px rgba(255, 255, 255, .1), 3px 3px 3px rgba(0, 0, 0, .5);
    text-align: center;
    border-radius: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 6px solid rgb(112, 255, 68);
    padding: 20px;
    font-size: 65px;
    width: 400px;
  }
  
  .galleryPics {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: 5%;
  }

  .imgName {
    flex-flow: row nowrap;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    background-color: rgba(210, 253, 141, 0.349);
    padding: 60px;
    border-radius: 60px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 10px solid rgba(255, 255, 255, 0.397);
    box-shadow: -15px 15px rgba(0, 0, 0, 0.267);
  }

  .imgNameDiv {
    color: #000000;
    font-size: 57px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .images_show {
    width: 600px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 8px black solid;
  }

  .hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 600px;
    /* Set the width */
    height: 600px;
    /* Set the height */
    /* Set border radius to create a circle */
    display: block;
    margin: 0 auto;
    /* Center the image */
  }

  .hover01 figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .column {
    padding: 0;
  }

  .column:last-child {
    padding-bottom: 60px;
  }

  .column::after {
    content: '';
    clear: both;
    display: block;
  }

  .column div {
    position: relative;
    float: left;
    margin: 0 0 0 25px;
    padding: 0;
  }

  .column div:first-child {
    margin-left: 0;
  }

  figure {
    width: 600px;
    height: 600px;
    margin: 0;
    padding: 0;
    background: #ffffff00;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    border: 8px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  .mintBtn {
    font-weight: bolder;
    font-size: 57px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(27, 27, 27);
    background-color: #ffec3e;
    border-radius: 12px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtn:hover {
    transform: scale(1.2);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mintBtnDis {
    font-weight: bolder;
    font-size: 57px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #00000000;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    color: rgb(122, 122, 122);
    background-color: #fcf191;
    border-radius: 12px;
    transition: transform .2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .introMint {
    font-size: 100px;
    font-weight: bolder;
    background-color: rgba(246, 147, 29, 1);
    padding: 32px;
    color: white;
    border-radius: 30px;
    box-shadow: -15px 15px rgb(201, 110, 0);
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    /* Add transition for a smooth effect */
  }

  .introMint:hover {
    box-shadow: none;
    /* Set box-shadow to none on hover */
  }

  .numbers {
    font-size: 50px;
    text-align: center;
    margin-bottom: 70px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #00000000;
    color: white;
    text-shadow: -4px -4px 4px rgba(255, 255, 255, .1), 4px 4px 4px rgb(0, 0, 0);
    border: none;
    margin-left: 30px;
    margin-right: 30px;
  }

  .boomGif {
    width: 20%;
    margin-top: -7%;
    z-index: 5;
  }

  .boomGifDiv {
    display: flex;
    justify-content: flex-end;
  }

  .rnDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -20%;
  }

  .rn {
    width: 20%;
    transform: scaleX(-1);
    margin-right: 3%;
  }

  .folks {
    bottom: 0;
    position: sticky;
    width: 20%;
  }

  .cool {
    bottom: 20px;
    position: sticky;
    width: 10%;
    margin-left: 3%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  .rocket {
    bottom: 0;
    position: sticky;
    width: 15%;
    margin-left: 85%;
    animation: zoomInOut 1.5s infinite alternate;
    /* Adjust the duration as needed */
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  .dynamite {
    width: 15%;
    margin-top: -7%;
    z-index: 5;
    bottom: 0;
    position: sticky;
  }

  .dynamiteDiv {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: sticky;
  }

  .boatDiv {
    z-index: 1020000000000;
  }

  .cont {
    margin-bottom: 5%;
  }
}